<template>
	<form class="main-wrapper mt-4 mb-5" @submit.prevent="handleSubmit">
		<LimitProductGeneral
			:id="id"
			ref="general"
			:name.sync="name"
			:status.sync="status"
		/>

		<div class="divider"></div>

		<LimitProductQuantity
			ref="quantity"
			:quantity.sync="quantity"
		/>

		<div class="divider"></div>

		<LimitProductDate
			ref="date"
			:status="status"
			:start-date.sync="startDate"
			:start-time.sync="startTime"
			:end-date.sync="endDate"
			:end-time.sync="endTime"
			:duration-time.sync="durationTime"
		/>

		<div class="divider"></div>

		<LimitProductSpecificSku
			ref="specificSku"
			:skus.sync="skus"
		/>

		<div class="divider"></div>

		<LimitProductCustomerGroup
			ref="customerGroup"
			:customer-groups.sync="customerGroups"
		/>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove Limit Product' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_LIST})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="handleRemove"
			title="Remove this limit product?"
			description="By removing this, all limit product info will be disappeared from the list."
			@onSuccess="$router.push({ name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_LIST})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import LimitProductDate from '@/components/limitProduct/LimitProductDate.vue';
import LimitProductGeneral from '@/components/limitProduct/LimitProductGeneral.vue';
import LimitProductQuantity from '@/components/limitProduct/LimitProductQuantity.vue';
import LimitProductSpecificSku from '@/components/limitProduct/LimitProductSpecificSku.vue';
import LimitProductCustomerGroup from '@/components/limitProduct/LimitProductCustomerGroup.vue';

import { ROUTE_NAME } from '../enums/route';
import { convertDateTimeToUTC, datetimeFormat } from '../assets/js/helpers';

export default {
	name: 'LimitProductForm',
	components: {
		LimitProductSpecificSku,
		LimitProductDate, // reuse component form banner form
		LimitProductGeneral,
		LimitProductQuantity,
		LimitProductCustomerGroup,
	},
	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			ROUTE_NAME,

			// form data
			id: null,
			name: '',
			quantity: 1,
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			durationTime: 0,
			skus: [],
			customerGroups: [],
		};
	},
	watch: {
		data: {
			// do handler immediate when create / mounted component
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.quantity = value.quantity;
					this.status = Boolean(value.isActive);
					this.startDate = value.startDate ? new Date(datetimeFormat(value.startDate)) : null;
					this.startTime = value.startTime ?? '00:00';
					this.endDate = value.endDate ? new Date(datetimeFormat(value.endDate)) : null;
					this.endTime = value.endTime ?? '23:59';
					this.durationTime = value.durationTime;
					this.skus = value.skus;
					this.customerGroups = value.customerGroups;
				}
			},
		},
	},
	methods: {
		...mapActions({
			deleteLimitProduct: 'limitProduct/deleteLimitProduct',
		}),

		handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const quantityRef = this.$refs.quantity.$v;
			const dateRef = this.$refs.date.$v;
			const specificSkuRef = this.$refs.specificSku.$v;
			const customerGroupRef = this.$refs.customerGroup.$v;

			generalRef.$touch();
			quantityRef.$touch();
			dateRef.$touch();
			specificSkuRef.$touch();
			customerGroupRef.$touch();

			if (
				!generalRef.$invalid &&
				!quantityRef.$invalid &&
				!dateRef.$invalid &&
				!specificSkuRef.$invalid &&
				!customerGroupRef.$invalid
			) {
				const payload = {
					name: this.name,
					is_active: this.status ? 1 : 0,
					quantity: this.quantity,
					start_date: convertDateTimeToUTC(this.startDate, this.startTime),
					end_date: convertDateTimeToUTC(this.endDate, this.endTime),
					duration_time: this.durationTime,
					skus: this.skus,
					customer_group_ids: this.customerGroups.map((item) => item.id),
				};

				this.$emit('onSubmit', payload);
			}
		},

		async handleRemove() {
			await this.deleteLimitProduct({ id: this.id });
		},
	},
};
</script>

<style scoped lang="scss">
.divider {
	border-top: 1px solid #ddd;
	margin: 32px 0;
}
</style>
