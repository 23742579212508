import { render, staticRenderFns } from "./LimitProductQuantity.vue?vue&type=template&id=2e4ebb38&"
import script from "./LimitProductQuantity.vue?vue&type=script&lang=js&"
export * from "./LimitProductQuantity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports